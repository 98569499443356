<template>
  <div class="lottery-list-container">
    <!-- 是否中签 -->
    <div
      class="col-center"
      :class="lotteryStatus ? 'lottery-style1' : 'lottery-style2'"
    >
      <p class="lottery-my-title">{{ lotteryStatus ? '恭喜您，您中签啦！' : '很遗憾，您未中签'}}</p>
      <p
        :class="lotteryStatus ? 'lottery-my1' : 'lottery-my2'"
        class="lottery-my-uid-phone"
      >UID： {{uid}}</p>
      <p
        :class="lotteryStatus ? 'lottery-my1' : 'lottery-my2'"
        class="lottery-my-uid-phone"
      >{{phoneNum}}</p>
    </div>
    <div class="col-center lottery-list-model">
      <p class="lottery-list-title">中签名单</p>
      <el-table
        class="lottery-list-table"
        :data="lotteryListInfo"
      >
        <el-table-column
          prop="uid"
          label="UID"
        >
        </el-table-column>
        <el-table-column
          prop="nickname"
          label="昵称"
        >
        </el-table-column>
        <el-table-column
          prop="phone"
          label="手机号"
        >
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import api from '../api/index-client'
import { Table,TableColumn } from 'element-ui';
Vue.use(Table)
Vue.use(TableColumn)

export default {
  data () {
    return {
      uid: '15212',
      phoneNum: '156****8888',
      lotteryStatus: false,
      lotteryListInfo: [
        {
          uid: '10044',
          nickName: '刚刚',
          phone: '177****6560'
        },

      ],
    }
  },
  mounted () {
    this.getLotteryInfo(2,this.$route.query.id)
    this.$store.commit('HIDE_APPLOADING')
  },
  methods: {
    // 获取报名抽签结果
    getLotteryInfo (type,id) {
      api
        .get('drawlots/getStatus?type=' + type + '&id=' + id)
        .then(result => {
          if (result.data.success) {
            this.uid = result.data.data.uid
            this.phoneNum = result.data.data.phone
            this.lotteryStatus = result.data.data.status
            this.lotteryListInfo = result.data.data.list
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
  },
}
</script>
<style lang="less" scoped>
.lottery-list-container {
  margin-top: 3.875rem;
  .lottery-style1 {
    margin-left: 3%;
    width: 94%;
    height: 150px;
    background-image: url('../assets/icon/lottery/lottery-bg.png');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
    background-position: 0% 0%;
    background-color: transparent;
    border-radius: 8px;
  }
  .lottery-style2 {
    margin-left: 3%;
    width: 94%;
    height: 150px;
    background: #262626;
    border-radius: 8px;
  }
  .lottery-my-title {
    font-size: 22px;
    font-family: lantingheiweight;
    font-weight: bold;
    color: #ffffff;
    line-height: 28px;
  }
  .lottery-my-uid-phone {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
  .lottery-my1 {
    color: rgba(255, 255, 255, 0.8);
  }
  .lottery-my2 {
    color: #999999;
  }
  .lottery-list-model {
    margin-left: 3%;
    width: 94%;
    min-height: 50vh;
    padding-bottom: 50px;
    background: #262626;
    margin-top: 12px;
    height: auto;
    .lottery-list-title {
      margin-top: 18px;
      font-size: 20px;
      font-weight: 500;
      color: #ffffff;
      line-height: 28px;
    }
  }
  .lottery-list-table {
    margin-top: 13px;
    width: 94%;
    background: #262626;
    border: 1px solid #3c3c3c;
    /deep/ tr,
    /deep/ th {
      background-color: #262626;
      border-bottom: 1px solid #3c3c3c;
    }
    /deep/th {
      font-size: 14px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #999999;
      line-height: 20px;
    }
    /deep/ td {
      border-bottom: 1px solid #3c3c3c;
      font-size: 14px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #999999;
      line-height: 20px;
    }
  }
  /deep/.lottery-list-table::before {
    display: none;
  }
}
</style>